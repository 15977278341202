<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="info">
        <a-descriptions bordered size="small" :column="1">
          <a-descriptions-item>
            <div slot="label" class="center">组织名称</div>
            <div class="">
              {{ groupDetail.name }}
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">组织类型</div>
            <div class="">
              <DataDictFinder
                dictType="specialOrg"
                :dictValue="groupDetail.type"
              />
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">组织职能</div>
            <div class="">
              {{ groupDetail.remarks }}
            </div>
          </a-descriptions-item>

          <!-- <a-descriptions-item>
            <div slot="label" class="center">分管领导</div>
            <div class="">
              {{ groupDetail.chargerName ? groupDetail.chargerName : "--" }}
            </div>
          </a-descriptions-item> -->

          <a-descriptions-item>
            <div slot="label" class="center">负责人</div>
            <div class="">
              {{ groupDetail.master1Name ? groupDetail.master1Name : "--" }}
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">负责人（副）</div>
            <div class="">
              {{ groupDetail.master2Name ? groupDetail.master2Name : "--" }}
            </div>
          </a-descriptions-item>

          <!-- <a-descriptions-item>
            <div slot="label" class="center">定编人数</div>
            <div class="">
              {{ groupDetail.staffing }}
            </div>
          </a-descriptions-item> -->

          <a-descriptions-item>
            <div slot="label" class="center">在编人数</div>
            <div class="">
              {{
                Array.isArray(groupDetail.employees)
                  ? groupDetail.employees.length
                  : "--"
              }}
            </div>
          </a-descriptions-item>
        </a-descriptions>
      </div>

      <GroupTree
        :deptId="deptId"
        :groupDetail="groupDetail"
        :groupVoList="groupVoList"
        :list="employees"
        @change="onChange"
      />
    </a-card>
  </div>
</template>

<script>
import GroupTree from "./components/group-tree.vue";
import { fetchDetailTree } from "@/api/setting/organization";

export default {
  components: {
    GroupTree,
  },

  data() {
    return {
      deptId: "",
      detail: {}, // 部门详情
      id: "", // 组/所 ID
    };
  },

  computed: {
    groupDetail() {
      let detail = {};
      if (Array.isArray(this.detail.instituteVoList)) {
        getDetail: for (
          let i = 0;
          i < this.detail.instituteVoList.length;
          i++
        ) {
          const item = this.detail.instituteVoList[i];
          console.log("item", item);
          if (item.id === this.id) {
            detail = item;
            break;
          }
          if (Array.isArray(item.groupVoList)) {
            for (let j = 0; j < item.groupVoList.length; j++) {
              const element = item.groupVoList[j];
              console.log("element", element);
              if (element.id === this.id) {
                detail = element;
                break getDetail;
              }
            }
          }
        }
      }
      return detail;
    },
    groupVoList() {
      return Array.isArray(this.groupDetail.groupVoList)
        ? this.groupDetail.groupVoList
        : [];
    },
    employees() {
      return Array.isArray(this.groupDetail.employees)
        ? this.groupDetail.employees
        : [];
    },
  },

  mounted() {
    const { id, dept } = this.$route.query;
    if (!id || !dept) return;
    this.deptId = dept;
    this.id = id;
    fetchDetailTree({
      uuid: dept,
    }).then((res) => {
      if (res) {
        this.detail = res;
      }
    });
  },

  methods: {
    onChange(id) {
      this.id = id;
    },
  },
};
</script>


<style scoped>
.container {
  position: relative;
}

.info {
  position: absolute;
  top: 8px;
  left: 8px;
}
</style>
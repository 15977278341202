<template>
  <div style="display: flex; height: 65vh">
    <div id="group-tree" style="flex: 1"></div>
  </div>
</template>

<script>
import { Graph, Dom } from "@antv/x6";
import dagre from "dagre";
import insertCss from "insert-css";

insertCss(`
  .x6-cell {
    cursor: pointer;
  }
`);

export default {
  props: {
    groupDetail: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    groupVoList: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    if (this.groupDetail.id) {
      this.initChart();
    }
  },

  destroyed() {
    this.graph?.dispose();
  },

  watch: {
    groupDetail() {
      if (this.groupDetail.id) {
        this.graph?.dispose();
        this.initChart();
      }
    },
  },

  methods: {
    initChart() {
      // 自定义节点
      Graph.registerNode(
        "user-node",
        {
          width: 260,
          height: 90,
          markup: [
            {
              tagName: "rect",
              attrs: {
                class: "card",
              },
            },
            {
              tagName: "image",
              attrs: {
                class: "image",
              },
            },
            {
              tagName: "text",
              attrs: {
                class: "name",
              },
            },
            {
              tagName: "text",
              attrs: {
                class: "position",
              },
            },
          ],
          attrs: {
            ".card": {
              rx: 10,
              ry: 10,
              refWidth: "100%",
              refHeight: "100%",
              fill: "#5F95FF",
              pointerEvents: "visiblePainted",
            },

            ".image": {
              x: 10,
              y: 10,
              width: 70,
              height: 70,
              // opacity: 0.7,
            },
            ".name": {
              refX: 0.4,
              refY: 0.4,
              fill: "#fff",
              fontSize: 20,
              fontWeight: "600",
              textAnchor: "start",
              textVerticalAnchor: "middle",
            },
            ".position": {
              refX: 0.4,
              refY: 0.6,
              fill: "#fff",
              fontSize: 14,
              textAnchor: "start",
            },
          },
        },
        true
      );

      Graph.registerNode(
        "dept-node",
        {
          width: 260,
          height: 90,
          markup: [
            {
              tagName: "rect",
              attrs: {
                class: "card",
              },
            },
            {
              tagName: "text",
              attrs: {
                class: "deptName",
              },
            },
            {
              tagName: "text",
              attrs: {
                class: "userName",
              },
            },
          ],
          attrs: {
            ".card": {
              rx: 10,
              ry: 10,
              refWidth: "100%",
              refHeight: "100%",
              fill: "#5F95FF",
              pointerEvents: "visiblePainted",
            },
            ".deptName": {
              refX: 0.1,
              refY: 0.4,
              fill: "#fff",
              fontSize: 20,
              fontWeight: "600",
              textAnchor: "start",
              textVerticalAnchor: "middle",
            },
            ".userName": {
              refX: 0.1,
              refY: 0.6,
              fill: "#fff",
              fontSize: 14,
              textAnchor: "start",
            },
          },
        },
        true
      );

      // 自定义边
      Graph.registerEdge(
        "user-edge",
        {
          zIndex: -1,
          attrs: {
            line: {
              strokeWidth: 2,
              stroke: "#A2B1C3",
              sourceMarker: null,
              targetMarker: null,
            },
          },
        },
        true
      );

      // 布局方向
      const dir = "LR"; // LR RL TB BT

      // 创建画布
      const graph = new Graph({
        container: document.getElementById("group-tree"),
        panning: true,
        mousewheel: {
          enabled: true,
        },
        selecting: true,
        autoResize: true,
      });

      const that = this;

      graph.on("cell:selected", (args) => {
        that.onClick(args);
      });

      this.graph = graph;

      // 自动布局
      function layout() {
        const nodes = graph.getNodes();
        const edges = graph.getEdges();
        const g = new dagre.graphlib.Graph();
        g.setGraph({ rankdir: dir, nodesep: 32, ranksep: 32 });
        g.setDefaultEdgeLabel(() => ({}));

        const width = 260;
        const height = 90;
        nodes.forEach((node) => {
          g.setNode(node.id, { width, height });
        });

        edges.forEach((edge) => {
          const source = edge.getSource();
          const target = edge.getTarget();
          g.setEdge(source.cell, target.cell);
        });

        dagre.layout(g);

        graph.freeze();

        g.nodes().forEach((id) => {
          const node = graph.getCell(id);
          if (node) {
            const pos = g.node(id);
            node.position(pos.x, pos.y);
          }
        });

        edges.forEach((edge) => {
          const source = edge.getSourceNode();
          const target = edge.getTargetNode();
          const sourceBBox = source.getBBox();
          const targetBBox = target.getBBox();

          if ((dir === "LR" || dir === "RL") && sourceBBox.y !== targetBBox.y) {
            const gap =
              dir === "LR"
                ? targetBBox.x - sourceBBox.x - sourceBBox.width
                : -sourceBBox.x + targetBBox.x + targetBBox.width;
            const fix = dir === "LR" ? sourceBBox.width : 0;
            const x = sourceBBox.x + fix + gap / 20;
            edge.setVertices([
              { x, y: sourceBBox.center.y },
              { x, y: targetBBox.center.y },
            ]);
          } else {
            edge.setVertices([]);
          }
        });

        graph.unfreeze();
      }

      function createNode(id, name, position, image) {
        return graph.createNode({
          id: "user," + id,
          shape: "user-node",
          attrs: {
            ".image": { xlinkHref: image },
            ".name": {
              text: Dom.breakText(name, { width: 240, height: 45 }),
            },
            ".position": {
              text: Dom.breakText(position ? position : "--", {
                width: 240,
                height: 25,
              }),
            },
          },
        });
      }
      function createDeptNode(id, deptName, userName) {
        return graph.createNode({
          id: "dept," + id,
          shape: "dept-node",
          attrs: {
            ".deptName": {
              text: Dom.breakText(deptName, { width: 260, height: 45 }),
            },
            ".userName": {
              text: Dom.breakText(userName, { width: 260, height: 25 }),
            },
          },
        });
      }

      function createEdge(sourceId, targetId) {
        return graph.createEdge({
          shape: "user-edge",
          source: { cell: sourceId },
          target: { cell: targetId },
        });
      }

      const nodes = [
        createDeptNode(
          this.groupDetail.id,
          this.groupDetail.name,
          `${this.groupDetail.master2Name}(负责人)`
        ),
      ];
      const edges = [];

      // 构建组
      if (Array.isArray(this.groupVoList)) {
        this.groupVoList.forEach((element) => {
          nodes.push(
            createDeptNode(
              element.id,
              element.name,
              element.master2Name + "(负责人)"
            )
          );
          edges.push(
            createEdge("dept," + this.groupDetail.id, "dept," + element.id)
          );

          if (Array.isArray(element.employees)) {
            element.employees.forEach((item) => {
              nodes.push(
                createNode(
                  item.id,
                  item.job ? `${item.name}(${item.job})` : item.name,
                  item.position,
                  item.header
                )
              );
              edges.push(createEdge("dept," + element.id, "user," + item.id));
            });
          }
        });
      }

      this.list.forEach((item) => {
        nodes.push(
          createNode(
            item.id,
            item.job ? `${item.name}(${item.job})` : item.name,
            item.position,
            item.header
          )
        );
        edges.push(
          createEdge("dept," + this.groupDetail.id, "user," + item.id)
        );
      });

      graph.resetCells([...nodes, ...edges]);
      layout();
      graph.zoomTo(0.6);
      graph.centerContent();
    },

    onClick(args) {
      const { id } = args.cell;
      const [type, _id] = id.split(",");
      if (type === "user") {
        this.$router.push("/human-resources/map/employee/detail?id=" + _id);
      } else {
        this.$emit("change", _id);
      }
    },
  },
};
</script>